import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import InputAdornment from "@material-ui/core/InputAdornment"
// @material-ui/icons
import Email from "@material-ui/icons/Email"
// core components
import Header from "components/Header/Header.jsx"
import HeaderLinks from "components/Header/HeaderLinks.jsx"
import Footer from "components/Footer/Footer.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardFooter from "components/Card/CardFooter.jsx"
import { container } from "assets/jss/material-kit-react.jsx"

import CustomInput from "components/CustomInput/CustomInput.jsx"

import image from "assets/img/bg7.jpg"

const slackPageStyle = {
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    paddingTop: "20vh",
    color: "#FFFFFF",
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)",
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""',
    },
    "& footer li a,& footer li a:hover,& footer li a:active": {
      color: "#FFFFFF",
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
    },
  },
  form: {
    margin: "0",
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "-40px",
    padding: "20px 0",
    marginBottom: "15px",
  },
  socialIcons: {
    maxWidth: "24px",
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
  },
  divider: {
    marginTop: "30px",
    marginBottom: "0px",
    textAlign: "center",
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important",
  },
  socialLine: {
    marginTop: "1rem",
    textAlign: "center",
    padding: "0",
  },
  inputIconsColor: {
    color: "#495057",
  },
}

class SlackPage extends React.Component {
  constructor(props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      email: "",
      submitted: false,
    }
  }

  sendToSlack = event => {
    const url = `${process.env.GATSBY_SLACK_USER_INVITE_URL}?email=${
      this.state.email
    }&token=${process.env.GATSBY_SLACK_TOKEN}`

    event.preventDefault()

    fetch(url, {
      method: "GET",
      headers: {
        // using 'application/json' here fails to handle CORS preflight 'options' request as it should on frontend JavaScript code used 'application/x-www-form-urlencoded' https://stackoverflow.com/questions/45752537/slack-incoming-webhook-request-header-field-content-type-is-not-allowed-by-acce
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })

    this.setState(prevState => {
      return { ...prevState, submitted: true }
    })
  }
  handleChange = e => {
    const value = e.target.value
    this.setState(prevState => {
      return { ...prevState, email: value }
    })
  }
  handleClick = event => {
    event.preventDefault()
    alert(this.state.email)
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" })
      }.bind(this),
      700
    )
  }
  render() {
    const { classes, ...rest } = this.props
    return (
      <div>
        <Header
          absolute
          color="transparent"
          brand="Utah Polynesian Professionals"
          rightLinks={<HeaderLinks />}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card className={classes[this.state.cardAnimaton]}>
                  {this.state.submitted && (
                    <div>
                      <CardHeader color="info" className={classes.cardHeader}>
                        <h4>Thank you for your interest!</h4>
                      </CardHeader>
                      <CardBody>
                        Check your email for an invitation to{" "}
                        <a href="https://utahpolypros.slack.com">Slack</a>!
                      </CardBody>
                    </div>
                  )}
                  {!this.state.submitted && (
                    <form className={classes.form} onSubmit={this.sendToSlack}>
                      <CardHeader color="info" className={classes.cardHeader}>
                        <h4>Join us on Slack!</h4>
                      </CardHeader>
                      <CardBody>
                        <CustomInput
                          labelText="Email..."
                          id="email"
                          name="email"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "email",
                            name: "email",
                            value: this.state.email,
                            onChange: this.handleChange,
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <a href="https://utahpolypros.slack.com">
                          Already on Slack? Click Here!
                        </a>
                      </CardBody>
                      <CardFooter className={classes.cardFooter}>
                        <Button
                          simple
                          color="primary"
                          size="lg"
                          type="submit"
                          onClick={this.sendToSlack}
                        >
                          Submit
                        </Button>
                      </CardFooter>
                    </form>
                  )}
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer whiteFont />
        </div>
      </div>
    )
  }
}

export default withStyles(slackPageStyle)(SlackPage)
